.container {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}
.containerClickable {
    cursor: pointer;
}

.image {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
}
